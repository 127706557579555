body {
    margin: 0;
}

.link {
    text-decoration: underline;
    cursor: pointer;
    color: white;
}

.link:hover {
    color: #00C7BF;
}

.link-black {
    text-decoration: underline;
    cursor: pointer;
    color: black;
}

.link-black:hover {
    color: #00C7BF;
}

.link-white {
    text-decoration: underline;
    cursor: pointer;
    color: white;
}

.link-white:hover{
    color: black;
}