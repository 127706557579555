.main{
    margin-left: 250px;
    height: calc(100vh - 56px);
    padding: 4rem;
    display: flex;
    justify-content: center;
}

.navbar{
    height: 56px;
    display: flex;
    padding: 1rem 4rem;
    justify-content: space-between;
    background-color: #626262;
    color: white;
}

.navbarItem{
    display: flex;
    flex-direction: row;
    /* width: 15rem; */
}