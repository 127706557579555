.darkHeader{
    background-color: #626262;
    padding: 0.5rem;
    color: white;
}

.formContainer{
    width: 100%;
    border: 1px solid #E7E7E7;
}

.formNewCondominium{
    padding: 2rem;
}