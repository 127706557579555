.sidebar {
    width: 250px;
    display: flex;
    align-items: center;
    position: fixed;
    background-color: #626262;
    height: calc(100vh - 56px);
    flex-direction: column;
    text-align: center;
    color: white;
}

.menuSection {
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid white;
}

.admins {
    background-color: #E58071;
}

.condominios {
    background-color: #626262;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.sidebar .avatar {
    background-color: #A9A9A9;
    padding: 1rem;
}

a {
    text-decoration: none;
    color: white;
}

a:hover{
    color: black;
}