.title {
    border: 0.1rem white solid;
    padding: 1rem 6rem;
    color: white;
}

.authcontainer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-image: url('../../assets/images/login1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
}

.authcontainer::after {
    content: "";
    background: rgba(0,0,0,0.23);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
}

.btnteal {
    background-color: #00C7BF;
    color: white;
	border: none;
	cursor: pointer;
	outline: inherit;
}